import type {
   LocalInitializerConfig,
   MappedObjectInitializerConfig,
} from "src/app/lite/local-db/resources/properties/properties.resource.types";
import type {
   FetchAndMapExceptParams,
   FetchAndMapToBooleansParams,
   MappedResponseObjectWithExceptions,
   ToExceptions,
   ZodPrimitive,
} from "src/app/lite/local-db/resources/properties/properties.resource.utils.params";
import { z } from "zod";

export function fetchAndMap<
   StoredObjectSchema extends z.AnyZodObject,
   ResponseObjectSchema extends z.AnyZodObject,
>(
   params: MappedObjectInitializerConfig<StoredObjectSchema, ResponseObjectSchema>,
): MappedObjectInitializerConfig<StoredObjectSchema, ResponseObjectSchema> {
   return params;
}

export function fetchAndMapToBooleans<
   ResponseObjectSchema extends z.AnyZodObject,
   Exceptions extends ReadonlyArray<keyof ResponseObjectSchema["shape"]>,
>(
   params: FetchAndMapToBooleansParams<ResponseObjectSchema, Exceptions>,
): MappedObjectInitializerConfig<
   MappedResponseObjectWithExceptions<ResponseObjectSchema, z.ZodBoolean, Exceptions>,
   ResponseObjectSchema
> {
   return fetchAndMapExcept({ ...params, mappedValidation: z.boolean() });
}

function fetchAndMapExcept<
   ResponseObjectSchema extends z.AnyZodObject,
   Exceptions extends ToExceptions<ResponseObjectSchema>,
   MappedValidation extends ZodPrimitive,
>({
   map,
   mappedValidation,
   exceptions: except,
   ...fetchAndMapParams
}: FetchAndMapExceptParams<
   ResponseObjectSchema,
   MappedValidation,
   Exceptions
>): MappedObjectInitializerConfig<
   MappedResponseObjectWithExceptions<ResponseObjectSchema, MappedValidation, Exceptions>,
   ResponseObjectSchema
> {
   const preservedValueSet = new Set(except);

   return {
      ...fetchAndMapParams,
      validation: mapSchemaProperties(
         fetchAndMapParams.responseValidation,
         mappedValidation,
         except,
      ),
      map: (responseObject) =>
         Object.fromEntries(
            Object.entries(responseObject).map(([key, value]) => [
               key,
               preservedValueSet.has(key) ? value : map(value),
            ]),
         ) as z.infer<
            MappedResponseObjectWithExceptions<
               ResponseObjectSchema,
               MappedValidation,
               Exceptions
            >
         >,
   };
}

function mapSchemaProperties<
   Schema extends z.AnyZodObject,
   MappedValidation extends ZodPrimitive,
   Exceptions extends ToExceptions<Schema>,
>(
   schema: Schema,
   mappedValidation: MappedValidation,
   exceptions?: Exceptions,
): MappedResponseObjectWithExceptions<Schema, MappedValidation, Exceptions> {
   const exceptionSet = new Set(exceptions);

   const zodBooleanShape = Object.fromEntries(
      Object.entries(schema.shape).map(([key, validation]) => [
         key,
         exceptionSet.has(key) ? validation : mappedValidation,
      ]),
   ) as z.ZodRawShape;

   return z.object(zodBooleanShape) as MappedResponseObjectWithExceptions<
      Schema,
      MappedValidation,
      Exceptions
   >;
}

export function local<Schema extends z.AnyZodObject>(
   params: LocalInitializerConfig<Schema>,
): LocalInitializerConfig<Schema> {
   return params;
}
