import { type OperatorFunction, map } from "rxjs";

export function defaultValue<T, R extends Exclude<T, undefined>>(
   defaultVal: R,
): OperatorFunction<T, Exclude<T, undefined>> {
   return map(
      (value: T) =>
         // eslint-disable-next-line typescript/prefer-nullish-coalescing -- We only want to check for undefined, not nullish
         (value === undefined ? defaultVal : value) as Exclude<T, undefined>,
      // eslint-enable-next-line typescript/prefer-nullish-coalescing
   );
}
