import { from, type Observable } from "rxjs";
import type { PropertiesResource } from "src/app/lite/local-db/resources/properties/properties.resource";
import type {
   InitializerConfig,
   ToShape,
} from "src/app/lite/local-db/resources/properties/properties.resource.types";
import { PropertiesStore } from "src/app/lite/local-db/resources/properties/properties.store";
import type { ToKey } from "src/app/lite/local-db/resources/properties/properties.store.types";
import { StorageService } from "src/app/lite/local-db/storage.service";
import type { z } from "zod";

export abstract class PropertiesStorageService<
   I extends InitializerConfig,
> extends StorageService<PropertiesStore<I>> {
   public constructor(resource: PropertiesResource<I>) {
      super(new PropertiesStore(resource));
   }

   protected get$<Key extends ToKey<I>>(
      key: Key,
      transaction: IDBTransaction,
   ): Observable<z.infer<ToShape<I>[Key]> | undefined> {
      return from(this.store.get({ key, transaction }));
   }

   protected async set<Key extends ToKey<I>>(
      key: Key,
      value: z.infer<ToShape<I>[Key]>,
      transaction: IDBTransaction,
   ): Promise<void> {
      await this.store.set({ key, value, transaction });
   }

   protected async clear<Key extends ToKey<I>>(
      key: Key,
      transaction: IDBTransaction,
   ): Promise<void> {
      await this.store.clear({ key, transaction });
   }
}
